.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
  margin-bottom: 15px;
}

.spinner {
  width: min-content;
}

.titleBox {
  display: flex;
  gap: 15px;
  align-items: center;
}

.selectBox {
  display: flex;
  align-items: center;
  gap: 10px;
  width: 200px;
}

.addButton {
  all: unset;
  width: 23px;
  height: 23px;
  cursor: pointer;

  transition: 200ms opacity;
}

.addButton:hover,
.addButton:focus {
  opacity: 0.6;
}

.addIcon svg {
  color: var(--color-border-primary-active);
}