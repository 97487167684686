.nameCellContent {
  display: flex;
  align-items: center;
  gap: 10px;
}

.button {
  all: unset;
  width: 25px;
  height: 25px;
  cursor: pointer;
  transition: 200ms opacity;
}

.button:hover,
.button:focus {
  opacity: 0.7;
}

.buttonName {
  all: unset;
  cursor: pointer;
}

.name:hover,
.name:focus {
  text-decoration: underline;
}

.today td {
  background-color: #e8f5ff;
}